<template>
  <div class="goodsSet">
    <div class="goodsSet-right">
      <div class="goodsSet-right-cont">
        <div class="goodsSet-right-cont-head">
          <div class="goodsSet-right-cont-head-list" v-if="0">
            <span style="width:130px;">选择门店：</span>
            <el-select v-model="value" placeholder="请选择" class="select">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodsSet-right-cont-head-list">
            <!-- <span>搜索：</span> -->
            <el-input
              v-model="input"
              placeholder="请输入搜索关键字"
              clearable
              @input="inputs"
              @clear="clears"
              class="input radius"
              style="width:100%"
            >
            </el-input>
          </div>
          <div class="goodsSet-right-cont-head-list">
            <span>分类：</span>
            <el-select
              v-model="value_s"
              placeholder="请选择商品分类"
              class="select"
            >
              <el-option
                v-for="item in options_s"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodsSet-right-cont-head-list">
            <span>状态：</span>
            <el-select
              v-model="options3_value"
              placeholder="请选择商品状态"
              class="select"
            >
              <el-option
                v-for="item in options3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="goodsSet-right-cont-head-list" style="margin-left:10px;">
            <el-button
              type="primary"
              style="width:100px"
              @click="searchs"
              icon="el-icon-search"
              >查询</el-button
            >
          </div>
          <div class="goodsSet-right-cont-head-list">
            <el-button
              type="danger"
              style="width:120px"
              plain
              icon="el-icon-plus"
              @click="edit(1)"
              >添加商品</el-button
            >
          </div>
        </div>
        <el-table
          :data="userSearchData"
          :border="$table('border')"
          :stripe="$table('stripe')"
          :header-cell-style="{ background: $table('bgcolor') }"
          style="width: 100%"
        >
          <!-- <el-table-column prop="id" label="序号" :width="$table('width')"> -->
          <el-table-column prop="id" label="序号" min-width="60">
            <template slot-scope="scope">
              <span v-if="currentPage4 < 2">
                {{ scope.$index + 1 }}
              </span>
              <span v-else>
                <!-- {{ ((scope.$index + 1) + currentPage4 * userSearchData.length * currentPage4) }} -->
                {{ (scope.$index + 1) + currentPage4 * 20 -20}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="picture" label="主图" min-width="90">
            <template slot-scope="scope">
              <div class="goodsSet-photo">
                <img
                  :src="scope.row.picture"
                  :alt="scope.row.name"
                  v-if="scope.row.picture"
                />
                <img
                  src="../../assets/none.png"
                  :alt="scope.row.name"
                  v-if="!scope.row.picture"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="商品名称" min-width="150"></el-table-column>
          <el-table-column prop="context" label="规格" min-width="120"></el-table-column>
          <el-table-column prop="price" label="价格(￥)" min-width="120"></el-table-column>
          <el-table-column prop="type" label="商品分类" min-width="120">
            <template slot-scope="scope">{{
              getworktype_name(scope.row.category_id, options)
            }}</template>
          </el-table-column>
          <el-table-column prop="stock" label="库存" min-width="120"></el-table-column>
          <el-table-column prop="status" label="状态" min-width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">上架中</span>
              <span style="color: #bbbbbb;" v-if="scope.row.status == 0"
                >已下架</span
              >
            </template>
          </el-table-column>
          <el-table-column label="编辑" fixed="right" min-width="150">
            <template slot-scope="scope">
              <div class="btn-box">
                <el-button
                  type="primary"
                  :data-d="scope.row"
                  plain
                  size="small"
                  @click="edit(0, scope)"
                  >编辑</el-button
                >
                <el-button
                  type="info"
                  plain
                  size="small"
                  @click="del(scope, 1)"
                  v-if="!(scope.row.status - 0)"
                  >上架</el-button
                >
                <el-button
                  type="danger"
                  plain
                  size="small"
                  @click="del(scope, 0)"
                  v-if="scope.row.status - 0"
                  >下架</el-button
                >
                <!-- <el-button
                  type="primary"
                  plain
                  size="small"
                  @click="delivery(scope)"
                  >分发</el-button
                > -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="goodsSet-right-cont-page">
          <el-pagination
            v-if="$pageStatus(total)[0]"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[20, 50, 80, 100]"
            :page-size="$pageStatus(total)[1]"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      :title="type ? '新增商品' : '修改商品'"
      :visible.sync="dialogVisible"
      width="1200px"
      :close-on-click-modal="false"
    >
      <div class="goodsSet-edit" v-if="0">
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>商品名称</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.name"
              clearable
            >
            </el-input>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>商品分类：</span>
            <el-select
              v-model="formLabelAlign.category_id"
              style="width: 100%;"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>商品规格：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.context"
              clearable
            >
            </el-input>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>商品条码：</span>
            <el-input
              placeholder="请输入内容"
              v-model="formLabelAlign.code"
              clearable
            >
            </el-input>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span style="width:115px;">成本价格：</span>
            <el-input-number
              style="width: 100%;"
              v-model="formLabelAlign.chengben"
              :precision="2"
              :step="0.1"
              :min="0.1"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="goodsSet-edit-list-item">
            <span style="width:115px;">零售价格：</span>
            <el-input-number
              style="width: 100%;"
              v-model="formLabelAlign.price"
              :precision="2"
              :min="0.1"
              :step="0.1"
              :max="999999"
            ></el-input-number>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span style="width:115px;">库存数量：</span>
            <el-input-number
              style="width: 100%;"
              v-model="formLabelAlign.stock"
              :step="1"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="goodsSet-edit-list-item">
            <span style="width:115px;">库存预警：</span>
            <el-input-number
              style="width: 100%;"
              v-model="formLabelAlign.stock_alarm"
              :step="1"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span style="width:115px;">排 序：</span>
            <el-input-number
              style="width: 243px;margin-left: -16px;"
              v-model="formLabelAlign.sort"
              :step="1"
              :min="1"
              :max="999999"
            ></el-input-number>
          </div>
          <div class="goodsSet-edit-list-item" v-if="0">
            <span style="width:100px;">小程序配置：</span>
            <el-checkbox v-model="formLabelAlign.wxapp"
              >启用小程序
            </el-checkbox>
            <!-- <el-checkbox
              v-model="formLabelAlign.wxstatus"
              :disabled="!formLabelAlign.wxapp"
              >启用小程序预约</el-checkbox
            > -->
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span style="width:100px;">是否上架：</span>
            <el-radio v-model="formLabelAlign.status" label="1">上架</el-radio>
            <el-radio v-model="formLabelAlign.status" label="0">下架</el-radio>
          </div>
        </div>

        <div class="goodsSet-edit-list">
          <span style="margin-right:15px;">商品主图：</span>

          <el-image
            v-if="formLabelAlign.picture"
            style="width: 148px; height: 148px"
            :src="formLabelAlign.picture"
            :preview-src-list="[formLabelAlign.picture]"
          >
          </el-image>
          <el-upload
            action="/api/app/meiye/upload/shop_photo"
            class="avatar-uploader"
            :show-file-list="false"
            :http-request="update"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
        <div class="goodsSet-edit-list" style="display:block;">
          <div
            class="goodsSet-edit-list-item"
            style="width:33.33%;height:60px;"
          >
            商品描述：
          </div>
          <!-- <vue-ueditor-wrap v-model="formLabelAlign.remark" :config="config" /> -->
          <i-quill-editor :content="formLabelAlign.remark" @textChange="handleTextChange"></i-quill-editor>
        </div>
      </div>
      <div class="goodsSet-edit">
        <div class="goodsSet-edit-list">
          <div class="projectSet-edit-list-img">
            <el-image
              v-if="formLabelAlign.picture"
              style="width: 160px; height: 160px;"
              :src="formLabelAlign.picture"
              :preview-src-list="[formLabelAlign.picture]"
            >
            </el-image>
            <el-image
              v-if="!formLabelAlign.picture"
              style="width: 160px; height: 160px;"
              :src="require('../../assets/none.png')"
              :preview-src-list="[require('../../assets/none.png')]"
            >
            </el-image>

            <el-upload
              action="/api/app/meiye/upload/shop_photo"
              class="avatar-uploader"
              :show-file-list="false"
              :http-request="update"
            >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="type"
                >上传主图</el-button
              >
              <el-button
                size="small"
                type="primary"
                style="margin:15px 0;"
                v-if="!type"
                >更换主图</el-button
              >
              <div slot="tip" class="el-upload__tip">
                <p>建议上传正方形尺寸大小</p>
                <p>小于500kb，200x200像素最佳</p>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>商品名称：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input
                placeholder="请输入名称"
                v-model="formLabelAlign.name"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>商品规格：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input
                placeholder="请输入规格"
                v-model="formLabelAlign.context"
                clearable
              >
              </el-input>
              <!-- <el-select v-model="context_options_val" placeholder="请选择">
                <el-option
                  v-for="item in context_options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> -->
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>零售价格：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.price"
                :precision="2"
                :min="0.1"
                :step="0.1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>商品条码：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input
                placeholder="请输入商品条码(非必填)"
                v-model="formLabelAlign.code"
                clearable
              >
              </el-input>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>是否上架：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-radio v-model="formLabelAlign.status" label="1"
                >上架</el-radio
              >
              <el-radio v-model="formLabelAlign.status" label="0"
                >下架</el-radio
              >
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>商城上架：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-radio v-model="formLabelAlign.wechat_status" label="1"
                >上架</el-radio
              >
              <el-radio v-model="formLabelAlign.wechat_status" label="0"
                >下架</el-radio
              >
            </div>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>商品分类：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-select
                v-model="formLabelAlign.category_id"
                style="width: 100%;"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>成本价格：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.chengben"
                :precision="2"
                :step="0.1"
                :min="0.1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>扣除成本：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-radio v-model="formLabelAlign.cb_status" label="0"
              >否</el-radio>
              <el-radio v-model="formLabelAlign.cb_status" label="1"
              >是</el-radio>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>库存数量：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.stock"
                :step="1"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
          <div class="goodsSet-edit-list-item">
            <span>库存预警：</span>
            <div class="goodsSet-edit-list-item-input">
              <el-input-number
                style="width: 100%;"
                v-model="formLabelAlign.stock_alarm"
                :step="1"
                :min="1"
                :max="999999"
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>
      <div class="goodsSet-edit-other">
        <!-- <div class="goodsSet-edit-other-title">项目描述</div> -->
        <div class="goodsSet-edit-other-title">商品描述</div>
        <div class="goodsSet-edit-other-cont">
          <!-- <vue-ueditor-wrap
            style="z-index:0;"
            v-model="formLabelAlign.remark"
            :config="config"
          /> -->
          <i-quill-editor style="z-index: 0;" :content="formLabelAlign.remark" @textChange="handleTextChange"></i-quill-editor>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencels">取 消</el-button>
        <el-button v-if="type" type="primary" @click="saves(1)">添加</el-button>
        <el-button v-if="!type" type="primary" @click="saves(0)"
          >保存修改</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="分发" :visible.sync="dialogVisible1" width="90%">
      <div class="goodsSet-edit">
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>商品名称：</span>
            <el-input
              placeholder="请输入内容"
              v-model="fromData.name"
              clearable
              disabled
            >
            </el-input>
          </div>
        </div>
        <div class="goodsSet-edit-list">
          <div class="goodsSet-edit-list-item">
            <span>分发到所有门店：</span>
            <el-switch
              v-model="fromData.all"
              active-text="全部"
              inactive-text="指定"
            >
            </el-switch>
          </div>
        </div>
        <div class="goodsSet-edit-list" v-if="!fromData.all">
          <div class="goodsSet-edit-list-item">
            <span>请选择分发门店：</span>
            <el-select v-model="fromData.list" multiple placeholder="请选择">
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delivery(0)">取 消</el-button>

        <el-button type="primary" @click="distribute">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import IQuillEditor from '../../components/home/i-quill-editor.vue';

export default {
  // components: { VueUeditorWrap },
  components: { IQuillEditor },
  data() {
    return {
      msg: "",
      config: {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 240,
        // 初始容器宽度
        initialFrameWidth: "100%",
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        serverUrl: "http://35.201.165.105:8000/controller.php",
        // serverUrl: "https://testeas.xiaohuadou.cn/wholesale/api/app/meiye/upload/product",
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的商品，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: "./UEditor/",
        zIndex: 3500,
      },
      dialogVisible: false,
      type: "", //保存和修改标识 1添加0编辑更新
      dialogVisible1: false,
      userData: [
        {
          id: 0,
          icon: "",
          number: "0002",
          name: "刘晓明",
          type: "1",
          department: "1",
          price: "100",
          experience_price: "88",
          unified_price: "99",
          unified_price_type: "1",
          img: "",
          time: "100",
          wxapp: 1,
          wxstatus: 1,
          info: "",
          work_peopel: "1",
          status: "1",
          wechat_status: "1",
        },
      ],
      userSearchData: [],
      userDataIndex: "",
      formLabelAlign: {
        remark: ''
      },
      options: [],
      options_s: [{ id: 0, name: "全部" }],
      value_s: 0,
      options1: [
        {
          value: "0",
          label: "白云技术部",
        },
        {
          value: "1",
          label: "白云技术部1",
        },
        {
          value: "2",
          label: "白云技术部2",
        },
      ],
      currentPage4: 1,
      pageSize: 1,
      total: 0,
      value: "0",
      input: "",
      radio: "",
      fromData: {
        id: "",
        name: "",
        all: false,
        list: [],
      },
      options3: [
        { id: 1, name: "上架" },
        { id: 0, name: "下架" },
        { id: 2, name: "全部" },
      ], //状态
      options3_value: 1,
      context_options_val: 1, //规格
      context_options: [
        { id: 1, name: "ML" },
        { id: 2, name: "个" },
        { id: 3, name: "件" },
      ],
    };
  },
  methods: {
    /**
     * 富文本内容改变
     */
    handleTextChange(value) {
      // console.log('textChange', value);
      this.formLabelAlign.remark = value ? value : '<p></p>';
    },
    /* 恢复搜索，搜索清除恢复内容 */
    inputs(val) {
      if (!val) {
        this.getList();
      }
    },
    searchs() {
      let val = this.input;
      this.input = val;
      //   let status = this.options3_value == 2 ? null : this.options3_value; //2等于全部状态
      // let types = this.value ? this.value : null; //0等于全部类型

      if (this.options3_value == 2 || !this.value_s || this.value_s) {
        this.currentPage4 = 1;
        this.getList();
        return false;
      }

      if (!val) {
        this.$message({
          message: "关键字不能为空",
          type: "warning",
        });
        return false;
      }
      this.currentPage4 = 1;
      this.getList();
    },
    changes() {
      if (!this.input) {
        this.clears();
        return false;
      }
      this.searchs();
    },
    clears() {
      this.searchs();
    },
    tabType(index) {
      this.tabIndex = index;
    },
    getType() {
      this.$axios({
        href: "/api/app/meiye/producttype/store",
        loading: 1,
      }).then((res) => {
        console.log(res);
        this.options = res.data;
        this.options_s = this.options_s.concat(res.data);
      });
    },
    getworktype_name(id, obj) {
      let d = obj;
      let len = d.length;
      for (let i = 0; i < len; i++) {
        if (d[i].id == id) {
          return d[i].name;
        }
      }
    },
    getList() {
      let status = this.options3_value == 2 ? null : this.options3_value; //2等于全部状态
      let types = this.value_s ? this.value_s : null; //0等于全部类型
      this.$axios({
        href: "/api/app/meiye/product/store",
        data: {
          page: this.currentPage4,
          category_id: types,
          status: status,
          name: this.input,
        },
      }).then((res) => {
        console.log(res);

        let len = res.data.items.length;

        for (let i = 0; i < len; i++) {
          res.data.items[i].status = res.data.items[i].status - 0 ? "1" : "0";
          res.data.items[i].cb_status = res.data.items[i].cb_status - 0 ? "1" : "0";
        }

        this.userData = res.data.items;
        this.userSearchData = res.data.items;
        this.total = res.data.total;
      });
    },
    updateList(obj) {
      this.$axios({
        href: "/api/app/meiye/product/edit",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "修改成功!",
        });
        this.getList();
        this.dialogVisible = false;
      });
    },
    addList(obj) {
      this.$axios({
        href: "/api/app/meiye/product/add",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "添加成功!",
        });
        this.getList();
        this.dialogVisible = !this.dialogVisible;
      });
    },
    delList(obj) {
      this.$axios({
        href: "/api/app/meiye/product/del",
        data: obj,
      }).then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getList();
      });
    },
    /* 添加更新 */
    edit(index, obj) {
      // console.log(obj);
      this.type = index;
      if (!index) {
        // this.userData.wxapp = this.userData.wxapp - 0 ? true : false;
        // this.userData.wxapp_wxstatus =
        //   this.userData.wxapp.wxstatus - 0 ? true : false;
        // this.userData.status = this.userData.status ? true : false;
        // this.unified_price_type = this.unified_price_type ? true : false;
        this.formLabelAlign = JSON.parse(
          JSON.stringify(this.userSearchData[obj.$index])
        );
        this.userDataIndex = obj.$index;
      }
      if (index) {
        this.formLabelAlign = {
          status: "1",
          wechat_status: "1",
          cb_status: "0",
          picture: "",
          stock: 999,
          stock_alarm: 999,
          chengben: 999,
          price: 999,
          sort: 1,
        };
      }
      this.dialogVisible = !this.dialogVisible;
    },
    del(obj) {
      console.log(obj.row.status);
      // if (!(obj.row.status - 0)) {
      //   this.$message({
      //     message: "此商品已经下架了哦，不需要重复操作",
      //     type: "warning",
      //   });
      //   return false;
      // }

      if (obj.row.status - 0) {
        this.$confirm("此操作将下架[" + obj.row.name + "], 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let d = obj.row;
            d.status = "0";
            this.updateList(d);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
        return false;
      }
      if (!(obj.row.status - 0)) {
        this.$confirm("此操作将上架[" + obj.row.name + "], 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let d = obj.row;
            d.status = "1";
            this.updateList(d);
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
        return false;
      }
    },
    delivery(obj) {
      console.log(obj);
      if (obj) {
        this.fromData = obj.row;
      } else {
        this.fromData = {};
      }
      this.dialogVisible1 = !this.dialogVisible1;
    },
    cencels() {
      this.dialogVisible = !this.dialogVisible;
      this.userDataIndex = "";
    },
    saves(obj) {
      let d = this.formLabelAlign;

      if (!d.name) {
        this.$message({
          message: "请输入商品名",
          type: "warning",
        });
        return false;
      }

      if (!d.context) {
        this.$message({
          message: "请输入商品规格",
          type: "warning",
        });
        return false;
      }
      if (!d.chengben) {
        this.$message({
          message: "请输入商品成本价格",
          type: "warning",
        });
        return false;
      }
      if (!d.price) {
        this.$message({
          message: "请输入商品价格",
          type: "warning",
        });
        return false;
      }

      if (d.chengben > d.price) {
        this.$message({
          message: "商品成本价格大于销售价格，请检查...",
          type: "warning",
        });
        return false;
      }

      if (!d.stock) {
        this.$message({
          message: "请输入商品库存",
          type: "warning",
        });
        return false;
      }

      if (d.stock <= d.stock_alarm) {
        this.$message({
          message: "商品库存小于或等于库存预警值哦，请检查...",
          type: "warning",
        });
        return false;
      }

      if (!d.picture) {
        this.$message({
          message: "请上传商品图片",
          type: "warning",
        });
        return false;
      }

      if (obj) {
        this.addList(d);
        return false;
      }
      this.updateList(d);
      // this.$message({
      //   type: "success",
      //   message: "修改成功!",
      // });
    },
    update(file) {
      const isJPG = ["image/jpeg", "image/png", "image/gif", "image/webp"];
      const isLt2M = file.file.size / 1024 / 1024 < 2;
      if (!isJPG.includes(file.file.type)) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 、WEBP格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
        return false;
      }
      let d = new FormData();
      d.append("image", file.file);
      d.append("token", sessionStorage.getItem("token"));
      this.$axios({
        href: "/api/app/meiye/upload/product",
        data: d,
      }).then((res) => {
        console.log(res, res.data.data.url);
        if (this.userDataIndex == "") {
          this.formLabelAlign.picture = res.data.data.url;
        } else {
          this.userSearchData[this.userDataIndex].picture = res.data.data.url;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
      console.log(file);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentPage4 = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getList();
    },
    distribute() {
      this.$message({
        type: "success",
        message: "分发成功!",
      });
      this.dialogVisible1 = !this.dialogVisible1;
    },
  },
  created() {
    this.getList();
    this.getType();
  },
};
</script>
<style lang="scss" scoped>
.goodsSet {
  width: 100%;
  height: 100%;
  display: flex;
  &-photo {
    width: 50px;
    height: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
  }

  &-right {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    // background: pink;
    &-cont {
      position: relative;
      width: 100%;
      height: 100%;
      background: #fff;
      &-head {
        width: 100%;
        // min-width: 1200px;
        height: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        position: relative;
        overflow: auto;
        // background: red;
        &-list {
          // width: 100%;
          height: 100%;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          span {
            display: inline-block;
            width: 50px;
          }
        }
        &-list:nth-of-type(1) {
          width: 445px;
        }
        &-list:nth-of-type(2) {
          width: 128px;
          margin-left: 10px;
        }
        &-list:nth-of-type(3) {
          width: 128px;
          margin-left: 10px;
        }
        &-list:nth-of-type(4) {
          width: 100px;
          margin-left: 10px;
        }
        &-list:nth-of-type(5) {
          width: 120px;
          position: absolute;
          right: 10px;
          top: 0;
        }
      }
      &-page {
        width: 100%;
        height: 60px;
        margin-top: 20px;
      }
    }
  }

  &-edit {
    width: 100%;
    min-height: 300px;
    display: flex;
    // background: red;
    &-display {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-list {
      //  width: 33.33%;
      box-sizing: border-box;
      min-height: 500px;

      &-item {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          display: inline-block;
          width: 70px;
        }
        &-input {
          width: 320px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        &-btn {
          position: absolute;
          cursor: pointer;
          background: #8088a8;
          color: #fff;
          top: 8px;
          right: 0;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &-item-display {
        justify-content: flex-start;
      }
    }

    &-list:nth-of-type(1) {
      padding: 24px;
      width: 224px;
      margin: 0 28.5px;
      // background: pink;
      min-height: 360px;
    }

    &-list:nth-of-type(2) {
      padding-top: 24px;
      min-width: 406px;
      min-height: 360px;
      margin-right: 57.5px;
      // background: blue;
    }
    &-list:nth-of-type(3) {
      padding-top: 24px;
      min-width: 415px;
      min-height: 360px;
      // background: pink;
    }
    &-other {
      width: 100%;
      min-height: 100px;
      &-title {
        width: 100%;
        height: 60px;
        font-size: 16px;
        font-family: Microsoft YaHei, Microsoft YaHei-Bold;
        font-weight: Bold;
        text-align: left;
        color: #000000;
        line-height: 60px;
      }
      &-cont {
        width: 100%;
        min-height: 60px;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }

  // &-edit {
  //   width: 100%;
  //   min-height: 500px;
  //   &-list {
  //     width: 100%;
  //     min-height: 40px;
  //     margin-bottom: 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: flex-start;
  //     img {
  //       width: 148px;
  //       height: 148px;
  //       margin-left: 15px;
  //     }
  //     &-item {
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       align-items: center;
  //       justify-content: flex-start;
  //       margin-right: 10px;
  //       span {
  //         display: inline-block;
  //         display: flex;
  //         align-items: center;
  //         justify-content: flex-start;
  //         width: 120px;
  //       }
  //     }
  //     &-item:last-child {
  //       margin-right: 0px;
  //     }
  //   }
  // }
}
</style>

<style moudle>
.goodsSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}

.goodsSet .el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 100%;
  min-height: 400px;
}
.goodsSet-right-cont-head {
  transition: all 0.5s;
}

.select {
  width: 80%;
}
.input {
  width: 76%;
}
.btn-box {
  width: 100%;
  height: 100%;
  display: flex;
}

@media screen and (max-width: 1600px) {
  .btn-box {
    display: block;
  }
  .btn-box button {
    float: left;
    margin: 0 5px 5px 0 !important;
  }
  .btn-box button:last-child {
    margin: 0 !important;
  }
}
.edui-editor {
  z-index: 0 !important;
}
/* .avatar-uploader {
  font-size: 20px;
  cursor: pointer;
  border: 1px dashed #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 148px;
  height: 148px;
  margin-right: 10px;
  border-radius: 10px;
  margin-left: 10px;
}
.avatar-uploader:hover {
  border: 1px dashed #409eff;
} */
.el-upload {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.radius .el-input__inner {
  border-radius: 20px;
}
</style>
